import './script';
import './uikit';
import './sprite';
import clearSubscribe from './subscribe';
import classSetter from './classSetter';

const input = document.querySelector('.js-subscribe-input');
const clearBtn = document.querySelector('.js-subscribe-clear');

clearSubscribe(input, clearBtn);

classSetter('table', ['uk-table', 'uk-table-responsive']);